// import Swiper from "swiper";

$(function () {
    const swiper = new Swiper(".swiper", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
        },
        keyboard: {
            enabled: true,
        },
        mousewheel: {
            enabled: false,
        },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev"
		},
        spaceBetween: 30,
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1441: {
                slidesPerView: 4,
            },
        },
    });

    const $swiperNav = $('.swiper-nav');
    $swiperNav.slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        rows: 0,
    });

    swiper.on('slideChange', function () {
        var swiperIndex = swiper.realIndex;
        $swiperNav.slick('slickGoTo', swiperIndex);
    });
    $swiperNav.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        swiper.slideToLoop(nextSlide); // `slideToLoop` to handle looping
    });
    $swiperNav.on('mouseenter', function () {
        swiper.autoplay.stop();
    });
});
