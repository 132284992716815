export default {
    mounted(el, binding) {
        // Define the click handler
        const handler = (e) => {
            if (!el.contains(e.target) && el !== e.target) {
                // Set the value of the binding to `false`
                if (binding.value instanceof Function) {
                    binding.value(false);
                }
                else {
                    console.warn(
                        'The v-out directive requires a function as its binding value.'
                    );
                }
            }
        };

        // Store the handler on the element for cleanup
        el.__outHandler__ = handler;

        // Add event listener
        document.addEventListener('click', handler);
    },

    unmounted(el) {
        // Remove the event listener during cleanup
        document.removeEventListener('click', el.__outHandler__);
        delete el.__outHandler__;
    },
};
