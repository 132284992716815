import {createApp} from "vue";
import OutDirective from './components/vue/directives/out';

window.mountVueComponent = (elementId, componentName, props = {}) => {
    // Find the element by its ID
    const el = document.getElementById(elementId);

    if (el) {
        // Dynamically import the Vue component
        import(`./components/vue/${componentName}.vue`).then((module) => {
            const app = createApp(module.default, props);
            app.directive('out', OutDirective);
            app.mount(el);
        }).catch((err) => {
            console.error(`Failed to load component ${componentName}:`, err);
        });
    }
    else {
        console.warn(`Element with ID "${elementId}" not found.`);
    }
};
