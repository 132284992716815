$(function () {
    $('.special_offer_block').each(function () {
        const $container = $(this);

        $(".special_offer_slider", $container).each(function () {
            $(this)
                .addClass('slider')
                .slick({
                    dots: true,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 1,
                    // adaptiveHeight: true,
                    arrows: true,
                    rows: 0,
                });
        });

        if ($(".image_container .image_container_slider img", $container).length > 1){
            $(".image_container .image_container_slider", $container).each(function () {
                $(this)
                    .slick({
                        dots: false,
                        infinite: true,
                        speed: 300,
                        slidesToShow: 1,
                        // adaptiveHeight: true,
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 5000
                    });
            });
        }

        $('.special_offer__nav .section_tab', $container).on('click', function () {
            const $t = $(this), filterClass = '.filter--' + $t.data('destination');
            $('.special_offer__nav .section_tab', $container).removeClass('active');
            $t.addClass('active');
            $(".special_offer_slider", $container).slick('slickUnfilter');
            $(".special_offer_slider", $container).slick('slickFilter', filterClass);
        });
        $('.special_offer__nav .section_tab', $container).eq(0).trigger('click');
    });
});
